<template>
    <col :o365-field="columnDefinition.colId" />
</template>

<script lang="ts">
export interface IProps {
    field?: string;
    editable?: boolean | ((node: NodeItem) => boolean);
    headerName?: string;
    headerTitle?: string;
    filter?: any;
    width?: number | string;
    minWidth?: string | number;
    flexWidth?: string | number;
    pinned?: 'left' | 'right';
    cellTitle?: string | ((node: NodeItem) => string);
    indent?: number | string;
    getDisplay?: (node: NodeItem) => any;
    boldDisplay?: boolean;

    cellStyle?: any
    classFn?: (node: NodeItem) => any;
    displayClassFn?: (node: NodeItem, col: DataColumn) => any,
    getCopyValue?: (node: NodeItem) => any;
    filterField?: string;
    sortField?: string;
    sortable?: boolean;
    disableMenu?: boolean;
    disableResize?: boolean;
    cellRendererParams?: any;
    cellrendererparams?: any;
    /** When true the default renderer will display column captions for group by configurations  */
    showPrefix?: boolean;
    /** When true the default rednerer will not display detail counts */
    hideCount?: boolean;
    /** Enable group by mode in grid */
    groupBy?: boolean;
    /** Enable client side node structure */
    loadFullStructure?: boolean;
    /** Enable user defined aggregates */
    userAggregates?: boolean;
    /** When enabled this column will only be visible in grid when there is active group by */
    onlyShowOnGroupBy?: boolean;
    singleClickEdit?: boolean,
    rowDrag?: boolean,
};

</script>

<script setup lang="ts">
/**
  * Special column configuration component used in grids to enable Node Data extension. 
  * @definition
  */
import type { NodeItem } from './DataObject.NodeItem.ts';
import type { DataColumn, DataGridControl } from 'o365-datagrid';
import type { Ref } from 'vue';

import { $t, InjectionKeys } from 'o365-utils';
import { onMounted, onUnmounted, computed, inject, useSlots, useAttrs, watch, markRaw } from 'vue';
import { TreeColumnHeaderFactory, ExpandableCellRenderer } from './DataGrid.NodeData.ts';
import DefaultCellRenderer from './components.DefaultCellRenderer.vue';
// import './DataGrid.RowDrag.ts';
import './DataGrid.RowDragInterface.ts';

const props = withDefaults(defineProps<IProps>(), {
    headerName: $t('Group'),
    filter: (raw) => (raw.field || raw.filterField) ? 'OFilter' : false,
    width: 400,
    minWidth: 50,
    indent: 24,
});

const slots = useSlots();
const attrs = useAttrs();

const colId = 'AutoTreeGroup';

const dataGridControl = inject<Ref<DataGridControl> | null>(InjectionKeys.dataGridControlKey, null);
const parentGroupId = inject(InjectionKeys.dataColumnGroupKey, null);

const TreeHeaderSlot = TreeColumnHeaderFactory(dataGridControl!.value, 'o365.vue.components.NodeDataLevels.vue');
/** Props combined with system column definition */
const columnDefinition = computed(() => {
    return {
        colId: colId,
        field: props.field,
        editable: props.editable,
        headerName: props.headerName,
        headerTitle: props.headerTitle ?? props.headerName,
        filter: props.filter,
        filterdropdown: () => '', // TODO: Custom filter dropdown for distinct groups. Low pri
        width: props.width,
        minWidth: props.minWidth,
        flexWidth: props.flexWidth,
        pinned: props.pinned,
        cellTitle: props.cellTitle,
        filterField: props.filterField,
        cellStyle: props.cellStyle,
        disableMenu: props.disableMenu,
        classFn: props.classFn,
        getCopyValue: props.getCopyValue,
        disableResize: props.disableResize,
        sortField: props.sortField,
        sortable: props.sortable,
        parentGroupId: parentGroupId,
        singleClickEdit: props.singleClickEdit == null ? false : props.singleClickEdit,
        hide: props.onlyShowOnGroupBy ? !dataGridControl.value.nodeData.shouldShowColumn() : undefined,
        hideFromChooser: props.onlyShowOnGroupBy ? !dataGridControl.value.nodeData.shouldShowColumn() : undefined,

        headerTextSlot: TreeHeaderSlot,
        // cellRenderer: markRaw(DefaultCellRenderer),
        // cellRendererParams: props.cellRendererParams ?? props.cellrendererparams ?? {
            // levelIndent: (typeof props.indent === 'string' ? parseFloat(props.indent) : props.indent),
            // getClass: (row, col) => { return props.displayClassFn ? props.displayClassFn(row, col) : undefined },
            // boldDisplay: props.boldDisplay
        // },
        cellRenderer: ExpandableCellRenderer,
        cellRendererParams: props.cellRendererParams ?? props.cellrendererparams ?? {
            handleClick: (row: NodeItem, _col: DataColumn) => {
                if (row.expanded) {
                    row.collapse();
                } else {
                    row.expand();
                }
            },
            getLeftMargin: (row: NodeItem) => (row.level ?? 0) * (typeof props.indent === 'string' ? parseFloat(props.indent) : props.indent),
            isExpandable: (row: NodeItem) => row.hasNodes,
            isCollapsed: (row: NodeItem) => !row.expanded,
            isLoading: (row: NodeItem) => row.isLoading,
            hasPrefix: props.showPrefix,
            getPrefix: (row: NodeItem) => {
                if (row.getConfiguration) {
                    const configuration = row.getConfiguration();
                    return configuration.type === 'groupby'
                        ? `${configuration.ui.title}: `
                        : null;
                } else {
                    return null;
                }
            },
            getDisplay: props.getDisplay ?? (row => {
                const value = row.displayValue ?? row[(props.field ?? 'ID')] ?? $t('Blank');
                return (props.hideCount || !row.count) ? value : `${value} (${row.count})`;
            }),
            getClass: (row,col ) => { return props.displayClassFn ? props.displayClassFn(row, col) : undefined },
            boldDisplay: props.boldDisplay,
        }
    };
});

watch(() => props.headerName, (newValue) => {
    changeColumnProperty('headerName', newValue);
});

function getDataColumn() {
    return dataGridControl?.value?.dataColumns?.getColumn(colId)
}

function changeColumnProperty(propName: string, value: any) {
    const dataColumn = getDataColumn();
    if (dataColumn) {
        if (!dataColumn.hasOwnProperty(propName)) { console.warn('Trying to set invalid prop for column ', propName, colId); return; }
        dataColumn[propName] = value;
    }
}

onMounted(() => {
    if (!dataGridControl?.value?.dataColumns?.initialColumnsMap[columnDefinition.value.colId]) {
        const container: HTMLElement = dataGridControl!.value.container ?? document.getElementById(dataGridControl!.value.id);
        const cols = Array.from(container.querySelector<HTMLElement>('.o365-column-definitions')?.querySelectorAll<HTMLElement>(`[o365-field]`) ?? []);
        const colIndex = cols.findIndex(x => x.getAttribute('o365-field') === columnDefinition.value.colId);
        if (colIndex === -1) { console.warn('Failed to mount column ', columnDefinition.value.colId); return; }
        if (props.groupBy) {
            dataGridControl.value.nodeData.groupBy = true;
        }
        if (props.loadFullStructure) {
            dataGridControl.value.dataObject.nodeData.loadFullStructure = true;
        }
        dataGridControl.value.nodeData.initialize2(props);
        dataGridControl!.value.asyncAddColumn({
            ...columnDefinition.value,
            class: attrs.class,
            cellRenderSlot: slots.default,
            cellEditorSlot: slots.editor,
            bulkUpdateEditorSlot: slots.batchupdateeditor,
            headerTextSlot: slots.headertext ?? columnDefinition.value.headerTextSlot,
            filterSlot: slots.filter,
            overlaySlot: slots.overlay,
        }, colIndex + 2);
        if (props.rowDrag) {
            window.setTimeout(() => {
                dataGridControl?.value.rowDrag.enable();;
            }, 250);
        }
    }
});

onUnmounted(() => {
    if (!dataGridControl?.value?.isBeingUnmounted) {
        dataGridControl!.value.removeColumn(columnDefinition.value.colId);
        delete dataGridControl!.value.dataColumns.initialColumnsMap[columnDefinition.value];
    }
});

defineExpose({ colId });

</script>
